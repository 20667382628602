import imagesLoaded from 'imagesloaded';

let isLoading = false

const showLoader = () => {
    document.documentElement.dataset.loading = 'true';
    document.getElementById('loader')?.classList.remove('hidden');
}

const hideLoader = () => {
    setTimeout(() => {
        document.documentElement.dataset.loading = 'false';
        document.getElementById('loader')?.classList.add('hidden');
    }, 500)
}

const updateProgressDisplay = (value: string) => {
    const progressText = document.querySelector('.loader__progress-text') as HTMLElement;

    if (progressText) {
        progressText.innerText = value;
    }
}

export const isDataLoaded = () => {
    return sessionStorage.getItem('imagesLoaded') === 'true'; 
}

const setDataLoaded = (resolve: (value?: unknown) => void): void => {
    sessionStorage.setItem('imagesLoaded', 'true')

    resolve()
}

const loadImages = async() => {
    return new Promise((resolve, reject) => {
        const mainContainer = document.getElementById('content__wrapper')

        if (!mainContainer) {
            reject(new Error("Content wrapper not found"));
            return;
        }

        const imgLoad = imagesLoaded(mainContainer, { background: true });

        imgLoad.on('progress', (instance, image) => {
            const percentComplete = Math.round((instance.progressedCount / instance.images.length) * 100);
            updateProgressDisplay(percentComplete.toString())
        })

        imgLoad.on('always', () => setDataLoaded(resolve))
        imgLoad.on('fail', reject)
    })
}

const onHome = () => {
    return window.location.pathname === '/' || window.location.pathname.includes("index")
}

export async function handlePageLoad() {
    if (isLoading) return;

    if (!isDataLoaded() && onHome()) {
        showLoader();

        try {
            await loadImages();
        } catch(error) {
            console.error('Image loading failed: ', error)
        } finally {
            hideLoader();
        }
    } else {
        // Still need to hide for the initial page load
        updateProgressDisplay("100")
        hideLoader()
    }

    isLoading = false
}