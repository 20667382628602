let lastScrollY = window.scrollY
let isAtTop = true

const hideHeader = () => {
    const header = document.querySelector('header') as HTMLElement

    header?.classList.add("hidden")

    if (isAtTop)
        toggleMiniClass(false)
}

const toggleMiniClass = (scrollingBackwards: boolean) => {
    const header = document.querySelector('header') as HTMLElement

    if (isAtTop || !scrollingBackwards)
        header?.classList.remove("mini")
    else
        header?.classList.add("mini")
}

const showHeader = () => {
    const header = document.querySelector('header') as HTMLElement

    header?.classList.remove("hidden")

    if ("true" === header.dataset.mini)
        return 

    toggleMiniClass(true)
}

const topCallback: IntersectionObserverCallback = (entries: IntersectionObserverEntry[]) => {
    const header = document.querySelector('header') as HTMLElement

    entries.forEach((entry) => {
        isAtTop = entry.isIntersecting;

        if (isAtTop) {
            showHeader()
            header?.classList.add("is-top")
        } else {
            header?.classList.remove("is-top")
        }
    })
}

const sectionCallback: IntersectionObserverCallback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach(entry => {
        if (isAtTop)
            return

        const currentScrollPosition = window.scrollY

        if (currentScrollPosition < lastScrollY) {
            showHeader()
        } else if(currentScrollPosition > lastScrollY) {
            hideHeader()
        }

        lastScrollY = currentScrollPosition
    })
}

const createTopElement = () => {
    const sentinel = document.createElement("div")
    sentinel.style.position = "absolute";
    sentinel.style.top = "0";
    // IMPROVEMENT: Arbitrary, 180-260 range seems ok
    sentinel.style.height = "260px";
    sentinel.style.width = "100%";
    sentinel.style.pointerEvents = "none";
    document.body.prepend(sentinel);

    return sentinel;
}

const handleShowHideHeader = (header: HTMLElement) => {
    const topObserverOptions: IntersectionObserverInit  = { 
        threshold: [0, .25, .5, 1],
        root: null, 
        rootMargin: '0px',      
    }

    const scrollObserverOptions: IntersectionObserverInit = {
        root: null,
        threshold: [0, .25, .5, 1],
    }

    const sectionObserver = new IntersectionObserver(sectionCallback, scrollObserverOptions)
    const topObserver = new IntersectionObserver(topCallback, topObserverOptions)

    topObserver.observe(createTopElement());

    const sections = document.querySelectorAll("section, footer")

    sections.forEach(section => {
        sectionObserver.observe(section)})
}

export const handleNavDisplay = () => {
    const header = document.querySelector('header') as HTMLElement

    handleShowHideHeader(header)
}