import LocomotiveScroll from 'locomotive-scroll';

const SCROLL_SPEED = 800;
const FOCUS_SPEED = SCROLL_SPEED + 50;

export const handleBackToTop = (scroll: LocomotiveScroll) => {
    const backToTop = document.getElementById('back__top')
    let isKeyboardNav = false;

    if (!backToTop)
        return

    backToTop.addEventListener('mousedown', (e) => {
        isKeyboardNav = false;
    })

    backToTop.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
            isKeyboardNav = true;
        }
    })

    backToTop.addEventListener('click', (e) => {
        e.preventDefault();
  
      scroll.scrollTo(0, {
        duration: SCROLL_SPEED
      })

      setTimeout(() => {
        if (isKeyboardNav) {
            const navSkip = document.querySelector('.nav__skip') as HTMLElement
            navSkip?.focus();
        } else {
            document.body.focus();
        }

      }, FOCUS_SPEED)
    })
}