import { handleNavDisplay, handleBackToTop, initSmoothScroll } from './scroll';
import { handleMenuButton } from './header';
import { handlePageLoad, isDataLoaded } from './loader';

const initializeHeader = () => {
  handleMenuButton();
  handleNavDisplay();
}

document.addEventListener('astro:before-preparation', ev => {
  if (isDataLoaded()) {
    const incomingDocument = ev.newDocument
    incomingDocument.getElementById('loader')?.classList.add('hidden')
  } 
})

document.addEventListener('astro:before-swap', ev => {
  if (isDataLoaded()) {
    const incomingDocument = ev.newDocument
    incomingDocument.getElementById('loader')?.classList.add('hidden')
  } 
})

document.addEventListener('astro:after-swap', handlePageLoad)

document.addEventListener('astro:page-load', () => {
  console.log('Designed & Developed by niyonarts');

  const smoothScroll = initSmoothScroll();
  initializeHeader()
  handlePageLoad()
  handleBackToTop(smoothScroll)
});

// Initial Load
handlePageLoad()