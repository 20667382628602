import { debounce } from "src/lib/utils";
const MOBILE_BREAKPOINT = 768;
const openClass = 'menu__open';
const transitionClass = 'menu__transitioning'

const setMenuButtonText = (menuText: string) => {
  const menuTrigger = document.querySelector('#menu__trigger') as HTMLElement;

  if (menuTrigger)
    menuTrigger.innerText = menuText;
};

const isLarger = () => {
  return window.innerWidth >= MOBILE_BREAKPOINT;
}

const isMobile = () => {
  return window.innerWidth < MOBILE_BREAKPOINT;
}

const getHeaderEl = (): HTMLElement => {
  return document.querySelector('header') as HTMLElement;
}

const isOpen = () => {
  const headerEl = getHeaderEl();

  return headerEl.classList.contains(openClass)
}

const handleCloseOnResize = () => {
  const headerEl = getHeaderEl();
  const menuIsOpen = headerEl?.classList.contains(openClass)

  if (!headerEl)
    return

  if (isLarger() && menuIsOpen) {
    closeMenu(false)
  }
}

const toggleNonMenuLinks = (open: boolean) => {
  const mainContent = document.getElementById('main')
  const footer = document.querySelector('footer')

  if (!open) {
    mainContent?.removeAttribute('inert')
    footer?.removeAttribute('inert')

  } else {
    mainContent?.setAttribute('inert', '')
    footer?.setAttribute('inert', '')
  }
}

const focusOnFirstItem = () => {
  const firstLink = document.querySelector('.link__nav li:first-child a') as HTMLElement;

  if (!firstLink)
    return

  firstLink.focus()

}

const handleEscapeKey = (event: KeyboardEvent) =>{
  if (event.key === "Escape" && isOpen()) {
    closeMenu(true)
  }
}

const openMenu = () => {
  const headerEl = getHeaderEl();

  focusOnFirstItem();

  headerEl.classList.add(transitionClass)
  setTimeout(() => headerEl.classList.add(openClass), 10)
  
  setMenuButtonText('close')
  toggleHiddenAriaItems(true)

  document.addEventListener('keydown', handleEscapeKey);
}

const closeMenu = (transition: boolean) => {
  const headerEl = getHeaderEl();
  headerEl.classList.remove(openClass);

  if (transition) {
    setTimeout(() => headerEl.classList.remove(transitionClass), 600)
  } else {
    headerEl.classList.remove(transitionClass)
  }
  setMenuButtonText('menu')
  toggleHiddenAriaItems(false)
  document.removeEventListener('keydown', handleEscapeKey);
}

// IMPROVEMENT: Need another name for this function
const toggleHiddenAriaItems = (open: boolean) => {
  toggleLogoTabIndex(open);
  toggleMenuAriaHidden(open)
  toggleNonMenuLinks(open)
}

const toggleMenuAriaHidden = (open: boolean) => {
  const nav = document.querySelector('nav')

  if (!nav)
    return

  if (open) {
    nav.setAttribute('aria-hidden', 'false')
    nav.setAttribute('aria-expanded', 'true')
    nav.removeAttribute('inert')
  }
  else {
    nav.setAttribute('aria-hidden', 'true')
    nav.setAttribute('aria-expanded', 'false')
    nav.setAttribute('inert', '')
  }
}

function handleLinkClose(){
  closeMenu(true);
}

const addLinkHandlers = () => {
  const navLinks = document.querySelectorAll('nav .link__default')

  if (!isLarger()) {
    navLinks.forEach((navLink) => {
      if (!navLink.hasClickListener)
        navLink.addEventListener('click', handleLinkClose)
        navLink.hasClickListener = true;
    })
  }
}

const removeLinkHandlers = () => {
  const navLinks = document.querySelectorAll('nav .link__default')

  if (!isLarger()) {
    navLinks.forEach((navLink) => {
      if (navLink.hasClickListener)
        navLink.removeEventListener('click', handleLinkClose)
        navLink.hasClickListener = false;
    })
  }
}

const handleResize = () => {
  handleCloseOnResize()
  handleNavInert()

  if (isMobile())
    addLinkHandlers()
  else {
    removeLinkHandlers()
    toggleLogoTabIndex(false)
  }
}

const handleNavInert = () => {
  const nav  = document.querySelector('nav') as HTMLElement

  if (!isMobile() || isOpen()) {
    nav?.removeAttribute('inert');
    return;
  }

  nav?.setAttribute('inert', "");
}

window.addEventListener('resize', debounce(handleResize, 200))

// Remove primary logo from tab index when the menu is open
const toggleLogoTabIndex = (open: boolean) => {
  const logo = document.querySelector('.logo a')

  if (!logo)
    return

  if (open) {
    logo.setAttribute('aria-hidden', 'false');
    logo.setAttribute('tabindex', '-1');

  }
  else {
    logo.setAttribute('aria-hidden', 'true');
    logo.removeAttribute('tabindex');
  }
}

export const handleMenuButton = () => {
  const menuTrigger = document.querySelector('#menu__trigger') as HTMLElement;
  
  if (!menuTrigger)
    return

  menuTrigger.addEventListener('click', () => {
    if (!isOpen()) {
      openMenu()
    } else {
      closeMenu(true)
    }
  });

  addLinkHandlers()
  handleCloseOnResize();
}

document.addEventListener('astro:page-load', () => {
  handleNavInert()
});