import LocomotiveScroll from 'locomotive-scroll';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);



export const initSmoothScroll = (): LocomotiveScroll => {
    const smoothScroll =  new LocomotiveScroll({
        lenisOptions: {
            wrapper: window,
            content: document.documentElement,
            lerp: 0.1,
            duration: 1.2,
            orientation: 'vertical',
            gestureOrientation: 'vertical',
            smoothWheel: true,
            smoothTouch: false,
            wheelMultiplier: 1,
            touchMultiplier: 2,
            normalizeWheel: true,
            easing: (t: number) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
        },
        initCustomTicker: (render) => {
            gsap.ticker.add(render)
        },
        // destroyCustomTicker: (render) => {
        //     gsap.ticker.remove(render)
        // }
    });


    gsap.ticker.lagSmoothing(0);

    return smoothScroll;
}